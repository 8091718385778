import { useEffect, useContext, lazy, Suspense } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Spin } from "antd";
// import config
import { ROUTE, ALL_ROUTES } from "./config/common";
// import component
import Navbar from "./components/common/Navbar";
import CustomModal from "./components/common/CustomModal";
import UnAuthenScreen from "./pages/unAuthenScreen";
import { matchPath } from "react-router-dom";
import ProtectedRoute from "./components/common/ProtectedRoute";
import { useLazyQuery } from "@apollo/client";
// import style
import "./styles/App.css";
import "./styles/theme.css";
import Sidebar from "./components/common/Sidebar";
import "antd/dist/antd.min.css";
import { checkLogin } from "./utilities/authenUtil";
import { PROFILE } from "./graphql/query";
// state management
import {
  CommonContext,
  CommonDispatchContext,
} from "./stateManagement/context/commonContext";
import { ACTIONS } from "./stateManagement/actions/commonAction";
import { LoadingOutlined } from "@ant-design/icons";

const HomeScreen = lazy(() => import("./pages/HomeScreen"));
const CashoutApprovalScreen = lazy(() =>
  import("./pages/CashoutApprovalScreen")
);
const CashoutApprovalDetailScreen = lazy(() =>
  import("./pages/CashoutApprovalDetailScreen")
);
const CashoutApprovedScreen = lazy(() =>
  import("./pages/CashoutApprovedScreen")
);
const ExportHistoryScreen = lazy(() => import("./pages/ExportHistoryScreen"));
const ParticipantScreen = lazy(() => import("./pages/ParticipantScreen"));
const ParticipantDetailScreen = lazy(() =>
  import("./pages/ParticipantDetailScreen")
);
const ResearcherScreen = lazy(() => import("./pages/ResearcherScreen"));
const ResearcherDetailScreen = lazy(() =>
  import("./pages/ResearcherDetailScreen")
);
const SurveyApprovalScreen = lazy(() => import("./pages/SurveyApprovalScreen"));
const SurveyApprovalDetailScreen = lazy(() =>
  import("./pages/SurveyApprovalDetailScreen")
);
const PreviewSurveyScreen = lazy(() => import("./pages/PreviewSurveyScreen"));
const UserVerification = lazy(() => import("./pages/UserVerification"));
const UserVerificationDetail = lazy(() =>
  import("./pages/UserVerificationDetail")
);
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ChangePassword = lazy(() => import("./pages/ChangePassword"));
const LoginScreen = lazy(() => import("./pages/LoginScreen"));
const TopupHistory = lazy(() => import("./pages/TopupHistory"));
const RewardScreen = lazy(() => import("./pages/RewardScreen"));
const RewardDetailScreen = lazy(() => import("./pages/RewardDetailScreen"));
const CreditScreen = lazy(() => import("./pages/CreditScreen"));
const CreditRequestScreen = lazy(() => import("./pages/CreditRequestScreen"));
const CreditTopupScreen = lazy(() => import("./pages/CreditTopupScreen"));
const CreditHistoryScreen = lazy(() => import("./pages/CreditHistoryScreen"));
const SubscriptionScreen = lazy(() => import("./pages/SubscriptionScreen"));
const ErrorFileScreen = lazy(() => import("./pages/ErrorFileScreen"));
const NotfoundScreen = lazy(() => import("./pages/Notfound"));

function App() {
  const dispatch = useContext(CommonDispatchContext);
  const { globalLoading } = useContext(CommonContext);
  const { isLogin: loggedIn } = checkLogin();
  const { pathname } = useLocation();
  const [profile] = useLazyQuery(PROFILE, {
    onCompleted: (data) => {
      dispatch({
        type: ACTIONS.SET_CURRENT_USER,
        currentUser: data?.profileAdmin,
      });
    },
  });

  useEffect(() => {
    if (loggedIn && pathname !== ROUTE.UNAUTHEN && pathname !== ROUTE.LOGIN && ALL_ROUTES.includes(pathname)) {
      profile();
    }
  }, [loggedIn, profile]);

  const hideNavRouteList = [
    ROUTE.SURVEY_DETAIL,
    ROUTE.REWARD_DETAIL,
    ROUTE.LOGIN,
    ROUTE.UNAUTHEN,
        ROUTE.ERRORFILE,
    ROUTE.PREVIEW_SURVEY,
    ROUTE.CHANGE_PASSWORD,
    ROUTE.FORGOT_PASSWORD,
    ROUTE.NOTFOUND
  ];
  const hideSidebarRouteList = [
    ROUTE.SURVEY_DETAIL,
    ROUTE.REWARD_DETAIL,
    ROUTE.LOGIN,
    ROUTE.UNAUTHEN,
        ROUTE.ERRORFILE,
    ROUTE.PREVIEW_SURVEY,
    ROUTE.CHANGE_PASSWORD,
    ROUTE.FORGOT_PASSWORD,
    ROUTE.NOTFOUND
  ];
  const hideGlobalLoadRouteList = [
    ROUTE.LOGIN,
    ROUTE.UNAUTHEN,
    ROUTE.ERRORFILE,
    ROUTE.CHANGE_PASSWORD,
    ROUTE.FORGOT_PASSWORD,
    ROUTE.NOTFOUND
  ];
  const splitPathname = pathname.split("/").filter((a) => a);
  return (
    <div className="App">
      {globalLoading && ALL_ROUTES.includes(pathname) &&
        !hideGlobalLoadRouteList.some((c) =>
          matchPath({ path: c }, pathname)
        ) && (
          <div id="global-loading">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} />} />
          </div>
        )}
      <CustomModal />
      {!ALL_ROUTES.includes(pathname) || hideNavRouteList.some((c) => matchPath({ path: c }, pathname)) ? null : (
        <Navbar />
      )}
      <div id="app-body-wrapper">
        {!ALL_ROUTES.includes(pathname) || hideSidebarRouteList.some((c) =>
          matchPath({ path: c }, pathname)
        ) ? null : (
          <Sidebar />
        )}
        <div
          id="content-container"
          className={
            splitPathname.map((name) => `${name}-screen`).join(" ") ||
            "home-screen"
          }
        >
          <Suspense
            fallback={
              <div className="w-full h-full flex items-center justify-center">
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 50 }} />}
                />
              </div>
            }
          >
            <Routes>
              <Route path={ROUTE.LOGIN} element={<LoginScreen />} />
              <Route
                path={ROUTE.ROOT}
                element={
                  <ProtectedRoute>
                    <HomeScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.RESEARCHER}
                element={
                  <ProtectedRoute>
                    <ResearcherScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.RESEARCHER_DETAIL}
                element={
                  <ProtectedRoute>
                    <ResearcherDetailScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.PARTICIPANT}
                element={
                  <ProtectedRoute>
                    <ParticipantScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.PARTICIPANT_DETAIL}
                element={
                  <ProtectedRoute>
                    <ParticipantDetailScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.SURVEY_APPROVAL}
                element={
                  <ProtectedRoute>
                    <SurveyApprovalScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.SURVEY_DETAIL}
                element={
                  <ProtectedRoute>
                    <SurveyApprovalDetailScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.PREVIEW_SURVEY}
                element={
                  <ProtectedRoute>
                    <PreviewSurveyScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.USER_VERIFICATION}
                element={
                  <ProtectedRoute>
                    <UserVerification />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.USER_VERIFICATION_DETAIL}
                element={
                  <ProtectedRoute>
                    <UserVerificationDetail />
                  </ProtectedRoute>
                }
              />
              <Route path={ROUTE.SURVEY_APPROVAL} element={<HomeScreen />} />
              <Route
                path={ROUTE.CASHOUT_APPROVAL}
                element={
                  <ProtectedRoute>
                    <CashoutApprovalScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.CASHOUT_APPROVED}
                element={
                  <ProtectedRoute>
                    <CashoutApprovedScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.CASHOUT_APPROVAL_DETAIL}
                element={
                  <ProtectedRoute>
                    <CashoutApprovalDetailScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.EXPORT_HISTORY}
                element={
                  <ProtectedRoute>
                    <ExportHistoryScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.TOPUP_HISTORY}
                element={
                  <ProtectedRoute>
                    <TopupHistory />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.REWARDS_APPROVAL}
                element={
                  <ProtectedRoute>
                    <RewardScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.REWARD_DETAIL}
                element={
                  <ProtectedRoute>
                    <RewardDetailScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.ICREDIT_APPROVAL}
                element={
                  <ProtectedRoute>
                    <CreditScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.ICREDIT_APPROVAL_REQUEST}
                element={
                  <ProtectedRoute>
                    <CreditRequestScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.ICREDIT_APPROVAL_TOPUP}
                element={
                  <ProtectedRoute>
                    <CreditTopupScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.ICREDIT_APPROVAL_HISTORY}
                element={
                  <ProtectedRoute>
                    <CreditHistoryScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.EMAIL_SUBSCRIPTION}
                element={
                  <ProtectedRoute>
                    <SubscriptionScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTE.CHANGE_PASSWORD}
                element={<ChangePassword />}
              />
              <Route
                path={ROUTE.FORGOT_PASSWORD}
                element={<ForgotPassword />}
              />
              <Route path={ROUTE.UNAUTHEN} element={<UnAuthenScreen />} />
               <Route
                path={ROUTE.ERRORFILE}
                element={
                  <ErrorFileScreen />
                }
              />
              <Route path={ROUTE.NOTFOUND} element={<NotfoundScreen />} />
                <Route path={'/export-history'} element={<></>} />
            <Route path="*" element={<Navigate to={ROUTE.NOTFOUND} replace />} />
            </Routes>
          </Suspense>
        </div>
      </div>
    </div>
  );
}

export default App;
